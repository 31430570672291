<template></template>

<script>

import store from '@/store'

export default {
  name: 'Logout',
  mounted() {
    store.dispatch('logout');
    store.dispatch('resetSelectedCompany');
    
    if (window.Tawk_API) {
      this.closeTawkTo();
    }

    this.$router.replace({ 
      name: 'Login' 
    });
  },
  
  methods: {
    closeTawkTo() {
      this.$Tawk.$endChat();
      this.$Tawk.$logout();
      this.$Tawk.$hideWidget();
      this.$Tawk.$shutdown();
    }
  }
}

</script>